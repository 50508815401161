import React, { useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img2 from '../Assets/image 9.svg'
import Banner from './Banner';
import Mensjersey from '../Components/Mensjersey';
import Navbar1 from '../Components/Navbar1';
import Newarrival from '../Components/Newarrival';
import Shopbycategory from '../Components/Shopbycategory';
import line from '../Assets/q16.png'
import tshirt from '../Assets/tshit.png'
import shoes from '../Assets/download.jpg'
import shoes2 from '../Assets/images.jpg'




function Home() {

  return (
    <>
      <Navbar />
      <Banner />
      <br />
      <br />
      <div className='tshirt-cards'>
        <div class="title">
          <h1 className='he'>Shop by Category</h1>
        </div>
        <br/>
        <Shopbycategory />
      </div>
      <br />
      <br />
      <br />

      <div className='home-category'>
        <div className='home-category1'>
          <div className='home-category-img'>
          </div>
          <div className='home-category-p'>
            <p>MEN'S SNEAKERS</p>

          </div>
        </div>
        <div className='home-category1'>
          <div className='home-category-img2'>

          </div>
          <div className='home-category-p'>
            <p>MEN'S DENIM</p>
          </div>
        </div>
      </div>

      <br />
      <div className='about-section'>
        <div className='about'>
          <div className='about-heading'>
            <div className='aboutheadings'>
              <h1>About</h1>
              <h2>BELLARAMIN</h2>
            </div>
            <div>
              <div class="container">
                <a href="#" class="button type--B">
                  <div class="button__line"></div>
                  <div class="button__line"></div>
                  <span class="button__text">ENTRY</span>
                  <div class="button__drow1"></div>
                  <div class="button__drow2"></div>
                </a>
              </div>
            </div>
            <div className='BEYOND'>
              <p>BEYOND GRAF is here to encourage self-expression with anything you wear, leaving conventional clothing behind. A fusion of streetwear and art that will tell your unique tale, and a place where talented creative minds can showcase their talents.</p>
            </div>
          </div>
          <br />
          <br />
          <div className='about-div'>
            <div className='about-div-left'>
              <img src={line} />
              <img className='tshirtimg' src={tshirt} />
            </div>
            <div className='about-div-right'>
              <p>We celebrate the diversity of street culture, attract individuals with unique, eye-catching graphics, and will revolutionize the fashion industry with self-expressive streetwear.</p>
              <br />
              <div>
                <a href="#" class="link">
                  <svg viewBox='0 0 200 200' width='200' height='200' xmlns='http://www.w3.org/2000/svg' class="link__svg" aria-labelledby="link1-title link1-desc">
                    <title id="link1-title">Come quick and click this</title>
                    <desc id="link1-desc">A rotating link with text placed around a circle with an arrow inside</desc>

                    <path id="link-circle" class="link__path" d="M 20, 100 a 80,80 0 1,1 160,0 a 80,80 0 1,1 -160,0" stroke="none" fill="none" />

                    <path class="link__arrow" d="M 75 100 L 125 100 L 110 85 M 125 100 L 110 115" fill="none" />

                    <text class="link__text">
                      <textPath href="#link-circle" stroke="none">
                        Come quick and click this
                      </textPath>
                    </text>
                  </svg>
                </a>


              </div>
            </div>

          </div>
          <div>

          </div>

        </div>
      </div>


      <div className='tshirt-cards'>
        <br />
        <br />

        <div class="title">
          <h1 className='he'>Essentials</h1>
          <h1 className='he'>MEN'S JERSEY</h1>

        </div>
        <br />
        <br />

        <Mensjersey />
        <br />
        <br />

      </div>
      <br />
      <div className='center'>
        <div class="link_wrapper">
          <a className='anch' href="#">DISCOVER</a>
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832">
              <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
            </svg>
          </div>


        </div>
      </div>
      <br />

<div className='newgallary'>
<img src={tshirt} alt="image" />
<img src={shoes} alt="image" />

<img src="https://images.unsplash.com/photo-1573655349936-de6bed86f839?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGFic3RyYWN0JTIwJTIweWVsbG93fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="image" />
<img src={shoes} alt="image" />
<img src="https://images.unsplash.com/photo-1573655349936-de6bed86f839?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGFic3RyYWN0JTIwJTIweWVsbG93fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="image" />
<img src="https://images.unsplash.com/photo-1541356665065-22676f35dd40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=327&q=80" alt="image" />


</div>

      {/* <section class="gallery">

        <div class="image">
          <img src={tshirt} alt="image" />

          <div class="overlay"><i class="bi bi-instagram"></i></div>
        </div>
        <div class="image">
          <img src={shoes} alt="image" />
          <div class="overlay"><i class="bi bi-instagram"></i></div>

        </div>
        <div class="image">
          <img src={shoes2} alt="image" />

          <div class="overlay"><i class="bi bi-instagram"></i></div>

        </div>
        <div class="image">
          <img src="https://images.unsplash.com/photo-1573655349936-de6bed86f839?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGFic3RyYWN0JTIwJTIweWVsbG93fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="image" />

          <div class="overlay"><i class="bi bi-instagram"></i></div>

        </div>
        <div class="image">
          <img src="https://images.unsplash.com/photo-1573655349936-de6bed86f839?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGFic3RyYWN0JTIwJTIweWVsbG93fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="image" />
          <div class="overlay"><i class="bi bi-instagram"></i></div>

        </div>
        <div class="image">
          <img src="https://images.unsplash.com/photo-1541356665065-22676f35dd40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=327&q=80" alt="image" />
          <div class="overlay"><i class="bi bi-instagram"></i></div>

        </div>

      </section> */}







      <br />
      <div className='tshirt-cards'>
      <div class="title">
          <h1 className='he'>Essentials</h1>
          <h1 className='he'>Checkout New Arrivals</h1>

        </div>
     
        <br />
        <Newarrival />
      </div>
      <br />
      <div class="marquee-container">
        <div class="marquee-content">

          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>
          <p>SHIPPING WORLDWIDE</p>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home