import React, { useEffect, useState } from 'react'
import rightArrow from "../Assets/right-arrow.png";
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import logo from '../Assets/image 91.svg'
import pdflogin from '../Assets/pdflogo.png'
import shoppay from '../Assets/shopify-svg__payments-shop-pay.svg'
import Gpay from '../Assets/SVG.svg'
import cartimg from '../Assets/Table → Body → Row → Data.svg'
import { AuthGetApi, CreteApi, CreteApiWithAuthorization } from '../Utilis/Api_call'
import { Button } from 'reactstrap';
import { toast } from "react-toastify";
import { Api_url } from '../Utilis/Api_url';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
function Cart() {

    const navigate = useNavigate()

    const [products, setCart] = useState([]);
    const [totalPrice, setotalPrice] = useState('');

    const [constant, setConstant] = useState([]);

    const [Userdetail, setUserdetail] = useState({});

    const [allprodcuts, setallprodcuts] = useState([]);
    const [selectpamntmode, setselectpamntmode] = useState('');
    const [paymentMessage, setPaymentMessage] = useState("");
    const [orderCreationId, setOrderCreationId] = useState("");

    const [show, setShow] = useState(false);



    const downloadPDF = async () => {

        const input = document.getElementById('pdf-content');

        const width = input.offsetWidth;
        const height = input.offsetHeight;

        try {
            const canvas = await html2canvas(input, {
                useCORS: true,
                scrollY: -window.scrollY,
                width: width,
                height: height
            });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt', [width, height]);
            pdf.addImage(imgData, 'PNG', 0, 0, width, height, '', 'FAST');
            pdf.save('invoice.pdf');
        } catch (error) {
            console.error('Error capturing content:', error);
        }
    };


    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        // Load Razorpay script
        loadScript("https://checkout.razorpay.com/v1/checkout.js")
            .then(() => {
                // Script loaded successfully
                console.log("Razorpay script loaded");
                // setRazorpay(true); // Set Razorpay loaded state
            })
            .catch((error) => {
                // Error while loading script
                console.error("Error loading Razorpay script:", error);
            });
    }, []);


    const getUserdetail = async () => {
        const token = localStorage.getItem("UserToken");
        try {
            const response = await AuthGetApi("/user/details", token);
            setUserdetail(response?.data?.data);
        } catch (err) {
            console.log(err);
        }
    }


    const fetchConstant = async () => {
        const token = localStorage.getItem("UserToken");
        try {
            const response = await AuthGetApi("/admin/constant", token);
            setConstant(response?.data?.data);
        } catch (err) {
            console.log(err);
        }
    };



    const fetchCart = async () => {
        const token = localStorage.getItem("UserToken");
        let totalPrice = 0;
        try {
            const response = await AuthGetApi("/user/cart", token);
            const transformedProducts = response?.data?.data?.products?.map(product => ({
                variant: product.varientId._id,
                quantity: product.quantity,
                product: product.productId._id
            }));
            setallprodcuts(transformedProducts);
            response?.data?.data?.products?.forEach((e) => {
                totalPrice += e?.varientId?.salePrice * e?.quantity;
            });

            setCart(response?.data?.data?.products);
            console.log(response?.data?.data?.products)
            setotalPrice(totalPrice)
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        fetchCart();
        fetchConstant();
        getUserdetail();
    }, [])


    const handleIncrement = async (productId) => {
        const newobj = {
            productId: productId?.productId?._id,
            varientId: productId?.varientId?._id,
        };
        newobj.quantity = productId?.quantity + 1;
        try {
            const token = localStorage.getItem("UserToken");
            const res = await CreteApiWithAuthorization("/user/cart", newobj, token);
        } catch (error) {
            console.log(error);
        }
        fetchCart();
    };

    const handleDecrement = async (productId) => {
        const newobj = {
            productId: productId?.productId?._id,
            varientId: productId?.varientId?._id,
        };
        try {
            const token = localStorage.getItem("UserToken");
            if (productId?.quantity == 1) {
                const res = await CreteApiWithAuthorization("/user/cart/remove", newobj, token);
            } else {
                newobj.quantity = productId?.quantity - 1;
                const res = await CreteApiWithAuthorization("/user/cart", newobj, token);
            }
        } catch (error) {
            console.log(error);
        }
        fetchCart();
    };


    const Placeorder = async () => {
        if (!selectpamntmode) {
            toast.error("Please Select Payment Type", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (!Userdetail?.shippingAddress) {
            toast.error("Please Update User address", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        PlaceorderCod()

    }

    const createOrder = async () => {
        const orderTotal = Number(totalPrice) + Number(constant?.constants[1]?.value);
        try {
            const res = await CreteApi(`/rzp/createorder`, {
                amount: orderTotal * 100,
                currency: "INR",
                receipt: "Receipt no. 1",
                notes: {
                    notes_key_1: "Tea, Earl Grey, Hot",
                    notes_key_2: "Tea, Earl Grey… decaf.",
                },
            });
            // if(token==""){
            //   return alert("Token not found.")
            // }
            // const response = await fetch(`${baseURL}/rzp/createorder`, {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //     Authorization: `Bearer ${token}`,
            //   },
            //   body: JSON.stringify({
            //     amount: total*100,
            //     currency: "INR",
            //     receipt: "Receipt no. 1",
            //     notes: {
            //       notes_key_1: "Tea, Earl Grey, Hot",
            //       notes_key_2: "Tea, Earl Grey… decaf.",
            //     },
            //   }),
            // });

            if (res.status != 200) {
                throw new Error("Failed to create order");
            }
            console.log(res);
            setOrderCreationId(res?.data?.data?.order?.id);
            return res?.data?.data?.order?.id;
        } catch (error) {
            console.error("Error creating order:", error);
            setPaymentMessage("An error occurred while creating order.");
            return null;
        }
    };


    const initializePayment = async (userOrderId) => {
        console.log(userOrderId);
        try {
            const orderId = await createOrder();

            if (!orderId) {
                return;
            }
            const orderTotal = Number(totalPrice) + Number(constant?.constants[1]?.value);
            const options = {
                key: "testingkey",
                amount: orderTotal * 100,
                currency: "INR",
                name: "Bellarmin",
                description: "Test Payment",
                // image: "https://example.com/your_logo.png",
                order_id: orderId,
                handler: async function (response) {
                    console.log(response);
                    // setRazorpayPaymentId(response.razorpay_payment_id);
                    // setRazorpayOrderId(response.razorpay_order_id);
                    // setRazorpaySignature(response.razorpay_signature);
                    verifyPayment(userOrderId, response);
                },
                prefill: {
                    name: "John Doe",
                    email: "john@example.com",
                    contact: "9999999999",
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#F37254",
                },
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.log(error);
        }
    };

    const verifyPayment = async (orderid, ids) => {
        const token = localStorage.getItem("UserToken");
        try {
            const response = await fetch(`${Api_url}/\rzp/payment-verification`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    // orderCreationId,
                    razorpayPaymentId: ids?.razorpay_payment_id,
                    razorpayOrderId: ids?.razorpay_order_id,
                    razorpaySignature: ids?.razorpay_signature,
                    orderid,
                }),
            });

            const result = await response.json();
            console.log(result);
            if (result.success) {
                alert("Order Place Successfully");
                setShow(true);

                setTimeout(async () => {
                    await downloadPDF();
                    setShow(false);
                    navigate("/");
                }, 100);
                setPaymentMessage("Payment verified successfully!");
            } else {
                setPaymentMessage("Payment verification failed.");
            }
        } catch (error) {
            console.error("Error verifying payment:", error);
            setPaymentMessage("An error occurred while verifying payment.");
        }
    };


    const PlaceorderCod = async () => {
        const newobj = {
            products: allprodcuts,
            shippingAddress: Userdetail?.shippingAddress,
            couponId: "",
            payment_mode: "COD",
            payment_status: "PENDING",
            order_status: "PLACED"
        };
        try {
            const token = localStorage.getItem("UserToken");
            const response = await CreteApiWithAuthorization("/user/order/place", newobj, token);
            console.log(response?.data?.data?.data?._id)
            if (response?.status == 200) {
                console.log(response?.data)
                if (selectpamntmode == "COD") {
                    setShow(true);

                    setTimeout(async () => {
                        await downloadPDF();
                        setShow(false);
                        navigate("/");
                    }, 100);
                    toast.success(response?.data?.data?.message, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (selectpamntmode == "Online") {
                    // console.log(res?.data?.data?.data?._id);
                    initializePayment(response?.data?.data?.data?._id);
                }


            }
        } catch (error) {
            toast.error(error?.response?.data?.error?.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log(error);
        }
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return (
        <>
            <Navbar />
            <br />
            <br />
            <div className='cart-section'>
                <div className='cart-section-left'>
                    <img className='cartlogo' src={logo} />
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p className='mt-3'>Cart</p>
                        <img src={rightArrow} alt="" />
                        <p className='mt-3'>Information</p>
                        <img src={rightArrow} alt="" />
                        <p className='mt-3'>Shipping</p>
                        <img src={rightArrow} alt="" />
                        <p className='mt-3'>Payment</p>
                    </div>
                    <br />
                    <p>Express checkout</p>

                    <div className='btns'>
                        <button className='shopay'><img src={shoppay} /></button>
                        <button className='gpay'><img src={Gpay} /></button>
                    </div>
                    <div className='lines'>
                        <div className='line'></div>
                        <p>OR</p>
                        <div className='line'></div>
                    </div>
                    {/* <div className='contact'>
                        <p>Contact</p>
                        <p>Have an account? Log in</p>
                    </div> */}
                    <div>
                        <p>Select payment type</p>
                        <div className='row' style={{ display: 'flex' }}>
                            <div className='Payment' style={{ marginRight: '10px' }} onClick={() => setselectpamntmode('Online')}>
                                <input type='radio' name='paymentType' value='Online' /> Online
                            </div>
                            <div className='Payment' onClick={() => setselectpamntmode('COD')}>
                                <input type='radio' name='paymentType' value='COD' /> COD
                            </div>
                        </div>
                    </div>
                    <br />
                    <button onClick={() => Placeorder()} className='conshop'>Place Order</button>
                </div>
                <div className='cart-section-right'>
                    {products && products.length > 0 ? (
                        products.map((product, index) => (
                            <div className='cartright' key={index}>
                                <img src={product?.productId?.productImageUrl} className='table-card-img' />
                                <sup className='quantitypower'>({product?.quantity})</sup>
                                <div className='RELEAS'>
                                    <h3>{product?.productId?.productTitle}</h3>
                                    <p>DENIM / {product?.varientId?.color?.color_name} / {product?.varientId?.size}</p>
                                    <div>
                                        <Button className='black-btn' style={{ marginRight: '10px' }} onClick={() => handleDecrement(product)}>-</Button>
                                        <span>{product?.quantity}</span>
                                        <Button className='black-btn' style={{ marginLeft: '10px' }} onClick={() => handleIncrement(product)}>+</Button>
                                    </div>
                                </div>
                                <p>₹{product.varientId?.salePrice * product?.quantity}</p>
                            </div>
                        ))
                    ) : (
                        <div>No Product in your Cart list</div>
                    )}
                    <hr />

                    <p className='coupon'>Enter your code</p>
                    <div className='code'>
                        <input />
                        <button>APPLY</button>
                    </div>
                    <br />
                    <hr />
                    <br />
                    {/* <div className='Subtotal'>
                        <p className='s'>Subtotal</p>
                        <p>₹{totalPrice}</p>
                    </div>

                    <div className='Subtotal'>
                        <p className='s'>Shipping </p>
                        <p>Calculated at next step</p>
                    </div>

                    <hr />
                    <div className='Subtotal'>
                        <p className='s'>Subtotal</p>
                        <p>₹{totalPrice}</p>
                    </div> */}
                    <div className='Subtotal'>
                        <p className='s'>Subtotal</p>
                        <p>₹{totalPrice}</p>
                    </div>
                    {constant && constant?.length !== 0 && (
                        <div className='Subtotal'>
                            <p className='s'>{constant?.constants[1]?.name}</p>
                            <p>₹{constant?.constants[1]?.value}</p>
                        </div>
                    )}
                    <hr />
                    <div className='Subtotal'>
                        <p className='s'>Grand Total</p>
                        {constant && constant?.length !== 0 && (
                            <p>₹{Number(totalPrice) + Number(constant?.constants[1]?.value)}</p>
                        )}
                        {!constant && (
                            <p>₹{totalPrice}</p>
                        )}
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />

            {show &&
                <div id="pdf-content" style={{ margin: '10%', backgroundColor: '#faf9eb' }} className='border border-primary'>
                    <div style={{ margin: '10%' }}>
                        <div>
                            <div className='row'>
                                <div className='col px-5'>
                                    <img src={pdflogin} />
                                </div>
                                <div className='col'>
                                    <h1 >Invoice</h1>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className='row'>
                                <div className='col  px-5'>
                                    <h4>BILLED TO:</h4>
                                    <h4>{Userdetail?.name}</h4>
                                    <h4>+91{Userdetail?.phoneNumber}</h4>
                                    <h4>{Userdetail?.shippingAddress?.street},{Userdetail?.shippingAddress?.city}</h4>
                                </div>
                                <div className='col  px-5'>
                                    <h4>Invoice Number: 12345</h4>
                                    <h4>{formattedDate}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div style={{ borderColor: "#00040a", borderWidth: '3px', marginTop: "20px", marginBottom: "20px", borderTopStyle: "solid" }} ></div>
                            <div className='row mt-3'>
                                <div className='col-6'>Item</div>
                                <div className='col-2'>Qunatity</div>
                                <div className='col-2'>Unit price</div>
                                <div className='col-2'>Total</div>
                            </div>
                            {products.map((product, index) => (
                                <>
                                    <div key={index} style={{ borderColor: "#6e6f70", borderWidth: '3px', marginTop: "20px", marginBottom: "20px", borderTopStyle: "solid" }} ></div>
                                    <div className='row mt-3' key={index}>
                                        <div className='col-6'>{product?.productId?.productTitle}</div>
                                        <div className='col-2'>{product?.quantity}</div>
                                        <div className='col-2'>Rs. {product.varientId?.salePrice}</div>
                                        <div className='col-2'>Rs.{product.varientId?.salePrice * product?.quantity}</div>
                                    </div>
                                </>
                            ))}
                            <div style={{ borderColor: "#6e6f70", borderWidth: '3px', marginTop: "20px", marginBottom: "20px", borderTopStyle: "solid" }} ></div>
                        </div>
                        <div className='mt-1'>
                            <div className='row'>
                                <div className='col-6'></div>
                                <div className='col-2'></div>
                                <div className='col-2'>SubTotal</div>
                                <div className='col-2'>₹{totalPrice}</div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-6'></div>
                                <div className='col-2'></div>
                                <div className='col-2'>Tax (0%)</div>
                                {constant && constant.constants && constant.constants.length >= 2 && (
                                    <div className='col-2'>₹{constant?.constants[1]?.value}</div>
                                )}
                            </div>
                            <div style={{ borderColor: "#00040a", borderWidth: '3px', marginTop: "20px", marginBottom: "20px", borderTopStyle: "solid" }} ></div>
                            <div className='row mt-2'>
                                <div className='col-6'></div>
                                <div className='col-2'></div>
                                <div className='col-2'><h4>Total</h4></div>
                                {constant && constant.constants && constant.constants.length >= 2 && (
                                    <div className='col-2'><h4>₹{Number(totalPrice) + Number(constant?.constants[1]?.value)}</h4></div>
                                )}
                            </div>
                        </div>
                        <div className='mt-4'>
                            <h1>Thankyou !</h1>
                        </div>
                        <div className='mt-5'>
                            <div className='row'>
                                <div className='col'>
                                    <div><h4>Payment Information</h4></div>
                                    <div>PNB Bank</div>
                                    <div>Account Name : {Userdetail?.name}</div>
                                    <div>Account No. : 1234567</div>
                                    <div>Pay By - {formattedDate}</div>
                                </div>
                                <div className='col'>
                                    <div><h2>{Userdetail?.name}</h2></div>
                                    <div>{Userdetail?.shippingAddress?.street},{Userdetail?.shippingAddress?.city}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <br />
            <br />
            <br />
            <br />
            <Footer />
        </>
    )
}

export default Cart