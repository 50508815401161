import React, { useEffect, useState } from 'react'
import { GetApi } from '../Utilis/Api_call';
import img from '../Assets/WhatsApp_Image_2024-02-03_at_12.14 1.svg'
import { useNavigate } from 'react-router-dom';

function Shopbycategory() {
    const navigate = useNavigate()

    const [Allcategory, setAllcategory] = useState([]);

    const fetchData = async () => {
        try {
            const response = await GetApi(`/product/category/all`);
            const data = response.data.data.categories;
            console.log("data", data);
            setAllcategory(data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const Navigatetocategorypage = (slug,id) => {
        // navigate(`/${slug}`);
        navigate(`/${slug}/${id}`);
    };
    return (
        <div className='tshirt categorygap' style={{ paddingLeft: '5%' }}>
            {Allcategory?.map((category, index) => (
                !category.parentId &&
                <div className='card-content' onClick={() => { Navigatetocategorypage(category?.slug, category._id) }}>
                    <div key={category._id} className={`tshirt-card${(index + 1) % 2 === 0 ? '2' : '3'}`}>
                        {
                            category?.imageUrl ?
                                <img src={category?.imageUrl} alt="T-shirt" style={{ width: '250px', height: '250px' }} />
                                :
                                <img src={img} alt="T-shirt" style={{ width: '250px', height: '250px' }} />
                        }
                    </div>
                    <div className='category-name'>{category?.name}</div>
                </div>
            ))}
        </div>
    )
}

export default Shopbycategory