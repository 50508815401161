import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import MenDenim from './Pages/MenDenim';
import ProductDetail from './Pages/ProductDetail';
import 'bootstrap/dist/css/bootstrap.css'
import Cart from './Pages/Cart';
import Profile from './Pages/Profile';
import WishList from './Pages/WishList';
import { ToastContainer } from 'react-toastify';
import Womens from './Pages/Womens';

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/men" element={<MenDenim />} /> */}
          <Route path="/:slug/:id" element={<MenDenim />} />
          {/* <Route path="/Women" element={<Womens />} /> */}
          <Route path="/product/:slug/:id" element={<ProductDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/account" element={<Profile />} />
          <Route path="/wishlist" element={<WishList />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
