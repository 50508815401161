import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { CreteApi } from '../Utilis/Api_call'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { fireEvent } from '@testing-library/react';
function SignUp() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [Fullname, setFullname] = useState("");
  const [Number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const handlesignup = async () => {

    if (!email.trim()) {
      toast.error("email Is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if (!Fullname.trim()) {
      toast.error("Fullname Is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if (!Number.trim()) {
      toast.error("Number Is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if (!password.trim()) {
      toast.error("password Is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

    try {
      const postData = {
        email: email,
        name: Fullname,
        phoneNumber: Number,
        password: password,
      };
      const response = await CreteApi('/user/signup', postData);
      toast.success(response?.data?.data?.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const { user } = response.data.data;

      localStorage.setItem("UserToken", user?.token);
      localStorage.setItem("Userid", user._id);
      localStorage.setItem("Userdata", JSON.stringify(user));
      navigate("/");
      setEmail("");
      setPassword("");
      window.location.reload();
      console.log('Authentication successful:', response.data);
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };



  return (
    <>
      <Navbar />

      <div className='login'>
        <h1>Create account</h1>
        <p style={{ textAlign: 'center' }}>Create an account to access orders, and customer information. Friends of Pearl can access special<br /> offers and first looks.
        </p>
        <div className='login-form singup-form'>
          <input
            placeholder='First Name'
            value={Fullname}
            onChange={(e) => { setFullname(e.target.value) }}
          />
          <br />
          <input
            placeholder='Number'
            value={Number}
            type='Number'
            onChange={(e) => { setNumber(e.target.value) }}
          />
          <br />
          <input
            placeholder='Email'
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
          />
          <br />
          <input
            placeholder='Password'
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
          />
          <br />
          <br />


          <button onClick={() => handlesignup()}>Create An Account</button>
          <br />

          <p>Already have an account? <b onClick={() => { navigate("/login") }}>Login</b></p>


        </div>

      </div>
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </>
  )
}

export default SignUp