import React, { useEffect, useState } from "react";
import logo from '../Assets/image 10.svg'
import icon from '../Assets/Button - Search → SVG.png'
import icon2 from '../Assets/profile.png'
import icon3 from '../Assets/SVG.png'
import icon4 from '../Assets/SVG-1.png'
import { Link } from "react-router-dom";
import "./header.css";
import * as HoverCard from '@radix-ui/react-hover-card';
import { ChevronDown, ChevronRight } from "lucide-react";
import { AuthGetApi, GetApi } from "../Utilis/Api_call";
import bag from '../Assets/BO_GIF (1).webp'
function Navbar() {
  const [open, setOpen] = useState(false);
  const [products, setCart] = useState([]);

  const fetchCart = async () => {
    const dataToken = localStorage.getItem("UserToken");
    let totalPrice = 0;
    try {
      const response = await AuthGetApi("/user/cart", dataToken);
      response?.data?.data?.products?.forEach((e) => {
        totalPrice += e?.varientId?.salePrice * e?.quantity;
      });

      setCart(response?.data?.data?.products);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchCart();
  }, []);

  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const dataToken = localStorage.getItem("UserToken");
    if (dataToken) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  return (
    <>
      <div className="topnav">
        <p>30% off storewide — Limited time! Shop Now</p>
      </div>
      <header>
        <div
          className="header-overlay"
          onClick={() => setOpen(false)}
          style={open ? { left: 0 } : { left: "100%" }}
        ></div>
        <button onClick={() => setOpen(!open)} className="bras-btn">
          <i class="bi bi-list"></i>
        </button>
        <ul
          className="header-list flex-1 md:items-center items-start "
          style={{ left: open ? "0" : "-300px" }}
        >
          <li className="list-item">
            <Link to="/">Home</Link>
          </li>
      
          {/* <MobileNav /> */}
          <NavLinks />

        </ul>

        <div className='logo'>
          <Link className='none' to='/'>
            <h2 className="logotop">BELLARMIN</h2>
          </Link>
        </div>
        {isLogin ? (
          <ul className='icons cpicons headerul search'>
            <li><img src={icon} alt="Icon" /></li>
            <Link className='none ' to='/account'>
              <li><img src={icon2} alt="Icon" /></li>
            </Link>
            <Link className='none' to='/wishlist'>
              <li><img src={icon3} alt="Icon" /></li>
            </Link>
            <Link className='none' to='/cart'>
              <li><img src={icon4} alt="Icon" /></li>
            </Link>
          </ul>
        ) : (
          <ul className='icons cpicons headerul'>
            <li><img src={icon} className="search" alt="Icon" /></li>
            <Link className='none' to='/login'>
              <li><img src={icon2} alt="Icon" /></li>
            </Link>
            <Link className='none' to='/login'>
              <li><img src={icon3} alt="Icon" /></li>
            </Link>
            {/* <Link className='none' to='/login'>
              <img className="bag" src={bag} />

            </Link> */}

          </ul>
        )}
      </header>

    </>
  );
}

const NavLinks = () => {
  const [category, setCategory] = useState([]);
  const allCategory = async () => {
    try {
      const response = await GetApi("/product/category/tree");
      setCategory(response?.data?.data.categoryTree);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    allCategory();
  }, []);

  return (
    <ul className="sub-list">
      {category?.map((item, index) => (
        <NavItem key={index} item={item} />
      ))}
    </ul>
  );
}

const NavItem = ({ item, isNested = false }) => {

  if (item.subCategories.length > 0) {
    return (
      <li className="sub-item mt-1" key={item?.id}>
        <HoverCard.Root className="relative">
          <HoverCard.Trigger>
            <Link to={`/${item.slug}/${item?._id}`} className="capitalize w-full cursor-pointer flex gap-1 items-center">
              {String(item.name).toLocaleLowerCase()}
              {isNested ? <ChevronRight className="ml-auto" size={20} /> : null}
              {!isNested ? <ChevronDown size={20} /> : null}
            </Link>
          </HoverCard.Trigger>
          <HoverCard.Content side={isNested ? "right" : "bottom"} className="absolute shadow-md rounded-lg z-10 bg-white">
            <ul className="sub-list flex flex-col p-4 shadow-lg ">
              {item.subCategories.map((subItem,index) => (
                <NavItem key={index} isNested item={subItem} />
              ))}
            </ul>
          </HoverCard.Content>
        </HoverCard.Root>
      </li>
    );
  }
  return <li className="sub-item" key={item?.id}>
    <Link to={`/${item.slug}/${item?._id}`} className="capitalize">
      {String(item.name).toLocaleLowerCase()}
    </Link>
  </li>
};

const MobileNav = () => {
  const [category, setCategory] = useState([]);
  const allCategory = async () => {
    try {
      const response = await GetApi("/product/category/tree");
      const categories = (response?.data?.data.categoryTree);
      //first women kids and men


      const sortOrder = { "women": 1, "kids": 2, "men": 3 };

      const sortedCategories = categories.sort((a, b) => {
        const orderA = sortOrder[a.slug] || Infinity;
        const orderB = sortOrder[b.slug] || Infinity;
        return orderA - orderB;
      });

      setCategory(sortedCategories);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    allCategory();
  }, []);

  return (
    <ul className="flex flex-col md:hidden pl-0 w-full gap-2">
      {category?.map((item, index) => (
        <MobileNavItem key={index} item={item} />
      ))}
    </ul>
  );
}

const MobileNavItem = ({ item }) => {

  if (item.subCategories.length > 0)
    return (
      <li key={item?.id} className="w-full">
        <Link to={`/${item.slug}/${item?._id}`} className="capitalize block  font-bold  w-full text-gray-700 hover:underline hover:text-gray-900  no-underline">
          {String(item.name).toLocaleLowerCase()}
        </Link>
        <ul className="pl-3 flex flex-col gap-0.5 ">
          {item.subCategories.map((subItem, index) => (
            <MobileNavItem key={index} item={subItem} />
          ))}
        </ul>
      </li>
    )

  return <li key={item?.id} className="w-full">
    <Link to={`/${item.slug}/${item?._id}`} className="capitalize underline block w-full  mb-1  text-gray-700 hover:underline hover:text-gray-900  ">
      {String(item.name).toLocaleLowerCase()}
    </Link>
  </li>
}



export default Navbar;
