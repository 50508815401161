import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Close from "../Assets/close-cross.svg";
import { AuthGetApi, CreteApiWithAuthorization, DeleteApiWithAuthorization } from '../Utilis/Api_call'
import { toast } from 'react-toastify';



function WishList() {

  const [wish, setWishList] = useState([]);
  const allWishList = async () => {
    const token = localStorage.getItem("UserToken");
    try {
      const response = await AuthGetApi(`/user/wishlist`, token);
      setWishList(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    allWishList()
  }, []);


  const deleteWishlist = async (id, vId) => {
    const token = localStorage.getItem("UserToken");
    try {
      const item = {
        productId: id,
        varientId: vId,
      }
      const response = await DeleteApiWithAuthorization("/user/wishlist/", item, token);
      console.log(response);
      if (response.status == 200) {
        allWishList();
      }
      toast.success("wishlist Remove successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
    }
    allWishList();
  };


  const handleMoveToCart = async (productId, varientId) => {
    const token = localStorage.getItem("UserToken");
    try {
      if (productId == "") {
        return alert("Product is not found.");
      }
      if (varientId == "") {
        return alert("Product Variant is not found.");
      }
      const item = {
        productId: productId,
        varientId: varientId,
      }
      const response = await CreteApiWithAuthorization("/user/wishlist/add-to-cart", item, token);
      if (response?.status === 200) {
        toast.success("Product Moved To cart successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        allWishList();
      }

      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };



  return (
    <>
      <Navbar />
      <br />
      <br />
      <div className='center personal-info'>
        <h1>Wishlist</h1>
        <p>To Save your wishlist please login or sign up</p>
      </div>
      <br />
      <br />
      <div className='wishlists-div'>
        <div className='wishlists'>
          {wish && wish.length > 0 ? (
            wish.map((product, index) => (
              <div className='wishlist' key={product?._id}>
                <button className='crossbutton' onClick={() => deleteWishlist(product?.product?._id, product?.variant?._id)}>
                  <img src={Close} className='h-100 w-100' alt="" /></button>
                <div className='wishlist-img'>
                  <img src={product?.product?.productImageUrl} alt={`Wish ${index + 1}`} className='w-100 h-120' />
                </div>
                <br />
                <h4>{product?.product?.productTitle}</h4>
                <h3>RS. {product?.product?.salePrice}</h3>
                <div className='DENIM'>
                  <p>{product?.variant?.color?.color_name}</p>
                </div>

                <div className='DENIM'>
                  <p>{product?.product?.productTitle?.slice(0, 12)}</p>
                </div>
                <div className='DENIM'>
                  <p>{product?.variant?.size}</p>
                </div>
                <div className='DENIM1' style={{ textAlign: 'center' }}>
                  <p onClick={() => handleMoveToCart(product?.product?._id, product?.variant?._id)}>ADD TO BAG</p>
                </div>
              </div>
            ))
          ) : (
            <div>No Product in your wish list</div>
          )}


          {/* <div className='wishlist'>
            <div className='wishlist-img'>
              <img src={img} className='w-100 h-100'/>

            </div>
            <br />
            <h4>MX-3- Perfect Indigo</h4>
            <h3>RS. 12,577,535.00</h3>
            <div className='DENIM'>
              <p>DENIM</p>
            </div>

            <div className='DENIM'>
              <p>PERFECT INDIGO</p>
            </div>
            <div className='DENIM'>
              <p>29</p>
            </div>
            <div className='DENIM1' style={{ textAlign: 'center' }}>
              <p>ADD TO BAG</p>
            </div>

          </div> */}

        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className='center'>
        <h1 className='ShareWishlist'>Share Wishlist</h1>
      </div>
      <br />
      <br />
      <br />    <br />
      <br />

      <br />
      <br />
      <div className='socialul-div'>

        <ul className='socialul'>
          <li>
            <a class="facebook" href="#">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <i class="bi bi-facebook"></i>
            </a>
          </li>
          <li>
            <a class="twitter" href="#">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <i class="bi bi-twitter"></i>
            </a>
          </li>
          <li>
            <a class="instagram" href="#">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <i class="bi bi-instagram"></i>
            </a>
          </li>
          <li>
            <a class="google" href="#">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <i class="bi bi-google"></i>
            </a>
          </li>
        </ul>




      </div>
      <Footer />
    </>
  )
}

export default WishList