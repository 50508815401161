import React, { useEffect, useState } from 'react'

import Footer from '../Components/Footer'
import profile from '../Assets/Ellipse 57.svg'
import Navbar from '../Components/Navbar'
import myorder from '../Assets/my orders.svg'
import USER from '../Assets/user.svg'
import singout from '../Assets/sign out.svg'
import img from '../Assets/Rectangle 755.svg'
import { AuthGetApi } from '../Utilis/Api_call'
import { Modal, Form, Button } from "react-bootstrap";
import axios from 'axios'
import { Api_url } from '../Utilis/Api_url'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'


function Profile() {

  const navigate = useNavigate()

  const [Userdetail, setUserdetail] = useState({});
  const [Userorder, setUserorder] = useState([]);
  const [activeTab, setActiveTab] = useState('info');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [country, setcountry] = useState("");
  const [email, setemail] = useState("");
  const [street, setstreet] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [suite, setsuite] = useState("");
  const [zip, setzip] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAddAddress = async () => {
    try {
      const token = localStorage.getItem("UserToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${Api_url}/user/address/update`,
        {
          shippingAddress: {
            firstName: firstName,
            lastName: lastName,
            country: country,
            email: email,
            street: street,
            city: city,
            state: state,
            suite: suite,
            zip: zip,
            phoneNumber: phoneNumber
          }
        },
        config
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(response.data);
      setfirstName('')
      setlastName('')
      setcountry('')
      setemail('')
      setstreet('')
      setstate('')
      setsuite('')
      setzip('')
      setzip('')
      setphoneNumber('')
      getUserdetail();
      closeModal();
    } catch (error) {
      console.log(error.response.data.error.message);
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const [activeTab1, setActiveTab1] = useState('active');
  const getUserdetail = async () => {
    const token = localStorage.getItem("UserToken");
    try {
      const response = await AuthGetApi("/user/details", token);
      setUserdetail(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  const getUserorders = async () => {
    const token = localStorage.getItem("UserToken");
    try {
      const response = await AuthGetApi("/user/order/all", token);
      setUserorder(response?.data?.data?.orders);
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    getUserdetail();
    getUserorders();
  }, []);

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  function formatDateWithAddedDays(timestamp) {
    // Parse the timestamp into a Date object
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 5);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const Logout = () => {
    toast.success("Logout Succesfull.", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    localStorage.clear();
    navigate("/");
  };

  console.log(Userorder, 'aa')

  return (
    <>
      <Navbar />
      <br />
      <br />
      <div className='center personal-info'>
        <h1>Account</h1>
        <img src={profile} />
        <h2>Hello,{Userdetail?.name}</h2>
        <p>Welcome to your Account</p>
      </div>
      <div className='acc'>
        <div className='sidebar'>
          <ul>
            <li className={activeTab === 'info' ? 'sidebar-active' : ''} onClick={() => setActiveTab('info')}>
              <img src={USER} alt="My Info" /> My info
            </li>
            <li className={activeTab === 'orders' ? 'sidebar-active' : ''} onClick={() => setActiveTab('orders')}>
              <img src={myorder} alt="My Orders" /> My orders
            </li>
            <li onClick={() => Logout()}> <img src={singout} alt="Sign Out" /> Sign out</li>
          </ul>
        </div>

        <div className='orders' style={{ display: activeTab === 'orders' ? 'block' : 'none' }}>
          <h1>My Orders</h1>
          <br />
          <div>
            <ul>
              <li className={activeTab1 === 'active' ? 'sidebar-activenew' : ''} onClick={() => setActiveTab1('active')}>Active</li>
              <li className={activeTab1 === 'cancelled' ? 'sidebar-activenew' : ''} onClick={() => setActiveTab1('cancelled')}>Cancelled</li>
              <li className={activeTab1 === 'completed' ? 'sidebar-activenew' : ''} onClick={() => setActiveTab1('completed')}>Completed</li>
            </ul>
          </div>
          <br />
          <div style={{ display: activeTab1 === 'active' ? 'block' : 'none' }}>
            {Userorder && Userorder?.length > 0 ? (
              Userorder?.map((product, index) => (
                <div key={index}>
                  <div className='orderinqq' >
                    <div className='orderinqq-left'>
                      <h2>Order no: #{product?._id}</h2>
                      <p><span>Order Date :</span>{formatDate(product?.createdAt)}  </p>
                      <p><span>Estimated Delivery Date :{formatDateWithAddedDays(product?.createdAt)}</span>  </p>
                    </div>
                    <div className='orderinqq-right'>
                      <p><span>Order Status :</span> {product?.order_status} </p>
                      <p><span>Payment Method :</span> {product?.payment_mode} </p>
                    </div>
                  </div>
                  {
                    product?.products?.map((productdata, index) => (
                      <div className='ordered-product' key={index}>
                        <div className='ordered-products'>
                          <img src={img} />
                          <div className='ordered-product-detail'>
                            <h3>Black Printed T-shirt</h3>
                            <p><span>Colour :</span> Pink </p>
                            <p><span>Qty :</span> {productdata?.quantity}</p>
                            <p><span>Total :</span> Rs.{productdata?.price}</p>
                          </div>
                        </div>
                        <div className='ordered-product-button'>
                          <button>View Detail</button>
                        </div>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <div>No Active Order Found in Order list</div>
            )}
          </div>
          <div style={{ display: activeTab1 === 'cancelled' ? 'block' : 'none' }}>
            {Userorder && Userorder?.length > 0 ? (
              Userorder?.map((product, index) => (
                <div key={index}>
                  <div className='orderinqq' >
                    <div className='orderinqq-left'>
                      <h2>Order no: #{product?._id}</h2>
                      <p><span>Order Date :</span>{formatDate(product?.createdAt)}  </p>
                      <p><span>Estimated Delivery Date :{formatDateWithAddedDays(product?.createdAt)}</span>  </p>
                    </div>
                    <div className='orderinqq-right'>
                      <p><span>Order Status :</span> {product?.order_status} </p>
                      <p><span>Payment Method :</span> {product?.payment_mode} </p>
                    </div>
                  </div>
                  {
                    product?.products?.map((productdata, index) => (
                      <div className='ordered-product' key={index}>
                        <div className='ordered-products'>
                          <img src={img} />
                          <div className='ordered-product-detail'>
                            <h3>Black Printed T-shirt</h3>
                            <p><span>Colour :</span> Pink </p>
                            <p><span>Qty :</span> {productdata?.quantity}</p>
                            <p><span>Total :</span> Rs.{productdata?.price}</p>
                          </div>
                        </div>
                        <div className='ordered-product-button'>
                          <button>View Detail</button>
                        </div>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <div>No Cancel Order Found in Order list</div>
            )}
          </div>
          <div style={{ display: activeTab1 === 'completed' ? 'block' : 'none' }}>
            {Userorder && Userorder?.length > 0 ? (
              Userorder?.map((product, index) => (
                <div key={index}>
                  <div className='orderinqq' >
                    <div className='orderinqq-left'>
                      <h2>Order no: #{product?._id}</h2>
                      <p><span>Order Date :</span>{formatDate(product?.createdAt)}  </p>
                      <p><span>Estimated Delivery Date :{formatDateWithAddedDays(product?.createdAt)}</span>  </p>
                    </div>
                    <div className='orderinqq-right'>
                      <p><span>Order Status :</span> {product?.order_status} </p>
                      <p><span>Payment Method :</span> {product?.payment_mode} </p>
                    </div>
                  </div>
                  {
                    product?.products?.map((productdata, index) => (
                      <div className='ordered-product' key={index}>
                        <div className='ordered-products'>
                          <img src={img} />
                          <div className='ordered-product-detail'>
                            <h3>Black Printed T-shirt</h3>
                            <p><span>Colour :</span> Pink </p>
                            <p><span>Qty :</span> {productdata?.quantity}</p>
                            <p><span>Total :</span> Rs.{productdata?.price}</p>
                          </div>
                        </div>
                        <div className='ordered-product-button'>
                          <button>View Detail</button>
                        </div>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <div>No Completed Order Found in Order list</div>
            )}
          </div>
          <div>
          </div>
        </div>
        <div className='orders' style={{ display: activeTab === 'info' ? 'block' : 'none' }}>
          <div className="my-right ">
            <h3 className="my-title pb-0" style={{ fontSize: '1.5rem', marginBottom: '20px' }}>My Info</h3>
            <h3 className="my-sub-title pb-0" style={{ fontSize: '1.25rem', marginBottom: '20px' }}>Contact Details</h3>
            <div className="info-box" style={{ marginBottom: '1rem' }}>
              <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>Your Name</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.name}</h6>
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>Change</h6>
              </div>
            </div>
            <div className="info-box" style={{ marginBottom: '1rem' }}>
              <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>Email Address</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.email}</h6>
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>Change</h6>
              </div>
            </div>
            <div className="info-box" style={{ marginBottom: '1rem' }}>
              <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>Phone Number</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.phoneNumber}</h6>
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>Change</h6>
              </div>
            </div>
            <div className="info-box" style={{ marginBottom: '1rem' }}>
              <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>Password</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="info-box-text" style={{ marginBottom: '0', display: 'flex' }}>
                  <li className="m-0"></li>
                  <li className="m-0"></li>
                  <li className="m-0"></li>
                  <li className="m-0"></li>
                  <li className="m-0"></li>
                  <li className="m-0"></li>
                </h6>
                <h6 className="info-box-text" style={{ marginBottom: '0' }}>Change</h6>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h3 className="my-sub-title pb-0 m-0" style={{ fontSize: '1.25rem', marginBottom: '0' }}>Address</h3>
              <h6 onClick={openModal} className="info-box-text" style={{ marginBottom: '0' }}>Update Address</h6>
            </div>
            <div>
              <Modal show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title className="mt-2">
                    Update Address
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    paddingLeft: "30px",
                    paddingTop: "10px",
                  }}
                >
                  <Form>
                    {/* <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="firstName" value={firstName}
                        onChange={(e) =>
                          setfirstName(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="lastName" value={lastName}
                        onChange={(e) =>
                          setlastName(e.target.value)
                        }
                      />
                    </Form.Group> */}
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="country" value={country}
                        onChange={(e) =>
                          setcountry(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="email" value={email}
                        onChange={(e) =>
                          setemail(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="street" value={street}
                        onChange={(e) =>
                          setstreet(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="city" value={city}
                        onChange={(e) =>
                          setcity(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="state" value={state}
                        onChange={(e) =>
                          setstate(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="suite" value={suite}
                        onChange={(e) =>
                          setsuite(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewAddress">
                      <Form.Control type="text" placeholder="zip" value={zip}
                        onChange={(e) =>
                          setzip(e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-2" controlId="formNewPincode">
                      <Form.Control type="number" placeholder="phoneNumber" value={phoneNumber}
                        onChange={(e) =>
                          setphoneNumber(e.target.value)
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ backgroundColor: "#4e2985" }}
                    variant="primary"
                    onClick={handleAddAddress}
                  >
                    Add Address
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <p className="info-box-text" style={{ marginBottom: '10px' }}>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>country</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.country}</h6>
                </div>
              </div>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>street</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.street}</h6>
                </div>
              </div>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>city</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.city}</h6>
                </div>
              </div>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>state</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.state}</h6>
                </div>
              </div>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>suite</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.suite}</h6>
                </div>
              </div>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>zip</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.zip}</h6>
                </div>
              </div>
              <div className="info-box" style={{ marginBottom: '1rem' }}>
                <h6 className="info-box-title" style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>phoneNumber</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="info-box-text" style={{ marginBottom: '0' }}>{Userdetail?.shippingAddress?.phoneNumber}</h6>
                </div>
              </div>
            </p>
          </div>


        </div>
      </div>
      <Footer />
    </>
  )
}

export default Profile
