import React, { useEffect, useState } from 'react'
import push from '../Assets/Button - Pause slideshow → SVG - Pause slideshow (1).svg'
import { GetApi } from '../Utilis/Api_call';

function Banner() {

    const [banner, setBanner] = useState([]);
    const slug = 'home-banner'

    const fetchData = async () => {
        try {
            const response = await GetApi(`/banners/slug/home-banner`);
            const data = response.data.data.banner;
            console.log("data", data);
            setBanner(data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const totalSlides = 4;
    const [currentSlide, setCurrentSlide] = useState(1);

    const changeSlide = (direction) => {
        let newSlide = currentSlide + direction;

        if (newSlide < 1) {
            newSlide = totalSlides;
        } else if (newSlide > totalSlides) {
            newSlide = 1;
        }

        setCurrentSlide(newSlide);
    };

    return (
        <div className='banner'>
            <div className='slider-div'>
                <div className='slider-div-img' style={{ backgroundImage: `url(./Assets/image${currentSlide}.svg)` }}>
                    <img src={push} alt={`Slide ${currentSlide}`} />
                    <p>AUTUMN-WINTER 2024 RUNWAY</p>
                    <p>{currentSlide} / {totalSlides}</p>
                </div>
                <div className='slider-div-chng'>
                    <i className="bi bi-arrow-left" onClick={() => changeSlide(-1)}></i>
                    <p>DISCOVER</p>
                    <i className="bi bi-arrow-right" onClick={() => changeSlide(1)}></i>
                </div>
            </div>
        </div>
    )
}

export default Banner