import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import bookmark from '../Assets/Button → SVG.svg'
import banner from '../Assets/Group 38.svg'
import sideimg from '../Assets/Group 32.svg'
import productimg from '../Assets/Section → product-recommendations → List → Item → ba986b1680df47c7b040ed41224e5b96.thumbnail.0000000000.jpg.svg'
import { Modal, ModalBody } from 'reactstrap'
import { ModalHeader } from 'reactstrap'
import arrow from '../Assets/Group 24.svg'
import { AuthGetApi, CreteApiWithAuthorization, DeleteApiWithAuthorization, GetApi } from '../Utilis/Api_call'
import { useNavigate, useParams } from 'react-router-dom'
import prodimage from '../Assets/Group 38.svg'
import img from '../Assets/WhatsApp_Image_2024-02-03_at_12.14 1.svg'
import { Button } from 'reactstrap';
import like from "../Assets/like.svg";
import activeLike from "../Assets/active-like.svg";
import { toast } from 'react-toastify'

function ProductDetail() {
  const navigate = useNavigate()
  const para = useParams();
  const id = para?.id

  const [modal, setmodal] = useState(false)


  const [productdetail, setproductdetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedimage, setselectedimage] = useState('');
  const [relatedproductslug, setrelatedproductslug] = useState('');
  const [Relatedproduct, setRelatedproduct] = useState([]);
  const [wish, setWishList] = useState([]);



  const fetchData = async () => {
    try {
      const response = await GetApi(`/product/${id}`);
      const data = response.data.data;
      // console.log("data", data);
      setproductdetail(data);
      setselectedimage(data?.product?.productImageUrl)
      setrelatedproductslug(data?.product?.category?.slug)
      setcurrentVariant(data?.variants[0]?._id).
        setIsLoading(false);
    } catch (error) {
      console.log("error detail", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const allWishList = async () => {
    const token = localStorage.getItem("UserToken");
    try {
      const response = await AuthGetApi(`/user/wishlist`, token);
      setWishList(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    allWishList()
  }, []);

  const likedArray = wish.filter(
    (ele) => id == ele?.product?._id
  );






  const Getallrelatedproducts = async () => {
    try {
      const response = await GetApi(`/product/category/products/${relatedproductslug}`);
      const data = response?.data?.data?.products;
      setRelatedproduct(data)
      console.log("data", data);
      setIsLoading(false);
    } catch (error) {
      console.log("error detail", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Getallrelatedproducts();
  }, [relatedproductslug]);


  const productdetailpage = (p) => {
    window.scrollTo(0, 0);
    navigate(`/product/${p?.productSlug}/${p?._id}`)
  };

  const [selectedVariant, setSelectedVariant] = useState(null);
  const [currentVariant, setcurrentVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const handleVariantSelect = (variant) => {
    setQuantity(1)
    setcurrentVariant(variant?._id)
    setSelectedVariant(variant);
  };

  const handleQuantityChange = (change) => {
    const newQuantity = quantity + change;
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };

  // Filter out duplicate sizes
  const uniqueSizes = productdetail?.variants?.reduce((acc, curr) => {
    if (!acc.includes(curr.size)) {
      acc.push(curr.size);
    }
    return acc;
  }, []);

  if (isLoading) {
    return <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      Loading...
    </div>;
  }




  const addToCart = async () => {
    const token = localStorage.getItem("UserToken");
    if (!token) {
      return navigate("/login");
    }
    try {
      const newItem = {
        productId: id,
        varientId: currentVariant,
        quantity: quantity,
      };
      const response = await CreteApiWithAuthorization("/user/cart", newItem, token);
      if (response?.status === 200) {
        // alert("cart add successfully!");
        toast.success("cart add successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setmodal(false)
    } catch (err) {
      console.log(err);
    }

  }

  const addToWishList = async (id) => {
    const token = localStorage.getItem("UserToken");
    if (!token) {
      return navigate("/login");
    }
    const item = {
      productId: id,
      varientId: currentVariant,
    }
    console.log(item)
    try {
      const response = await CreteApiWithAuthorization("/user/wishlist", item, token);
      if (response.status == 200) {
        allWishList();
      }
      // alert("wishlist add successfully!");
      toast.success("wishlist add successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteWishlist = async (id) => {
    const token = localStorage.getItem("UserToken");
    if (!token) {
      return navigate("/login");
    }
    try {
      const item = {
        productId: id,
        varientId: currentVariant,
      }
      const response = await DeleteApiWithAuthorization("/user/wishlist/", item, token);
      console.log(response.data)
      if (response.status == 200) {
        allWishList();
      }
      // alert("wishlist Remove successfully!");
      toast.success("wishlist Remove successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
    }
    allWishList();
  };



  return (
    <>
      <Navbar />
      <div className='banner-scroller' style={{ backgroundImage: `url(${selectedimage})` }}>
        <div className='RELEASE'>
          <h1>{productdetail?.product?.productTitle}</h1>
          <h2><span>RS. {productdetail?.product?.salePrice}</span> <span>FADED BLACK</span></h2>
          <button onClick={() => setmodal(true)}>Select Size</button>
          <Modal size='lg'
            isOpen={modal}
            toggle={() => setmodal(!modal)}>
            <ModalHeader style={{ textAlign: 'center' }} toggle={() => setmodal(!modal)}>
              <p className='text-center'>Size: {selectedVariant?.size}</p>
            </ModalHeader>
            <ModalBody>
              <p className='arrow'><img src={arrow} />SIZE GUIDE</p>
              {uniqueSizes?.map(size => {
                const variant = productdetail.variants.find(variant => variant.size === size);
                return (
                  <div className='sizeing' key={variant?._id} onClick={() => handleVariantSelect(variant)}>
                    <p>{selectedVariant && selectedVariant?.size === size ? <span className="dot"></span> : null}Size: {size}</p>
                    {variant.stock > 0 ? (
                      <p>Available</p>
                    ) : (
                      <p>ONLY ITEM LEFT</p>
                    )}
                  </div>
                );
              })}
              {selectedVariant && (
                <div className="text-center mb-4 ">
                  <p>Selected Variant: {selectedVariant?.size}</p>
                  <div>
                    <Button style={{ marginRight: '10px' }} onClick={() => handleQuantityChange(-1)}>-</Button>
                    <span>{quantity}</span>
                    <Button style={{ marginLeft: '10px' }} onClick={() => handleQuantityChange(1)}>+</Button>
                  </div>
                </div>
              )}
          
              <div className='center'>
              <br/>
                <Button onClick={addToCart} className='addbutton'>ADD TO BAG</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
      {
        likedArray && likedArray?.length > 0 ? (
          <button
            className="fvt"
            onClick={() => deleteWishlist(id)}
          >
            <img src={activeLike} alt="" />
          </button>
        ) : (
          <button
            className="fvt"
            onClick={() => addToWishList(id)}
          >
            <img src={like} alt="" />
          </button>
        )
      }
<div className='sideimg-div'>

      <div className='sideimg'>
        {productdetail?.images[0]?.imageUrls?.map((imageUrl, index) => (
          <div key={imageUrl?._id} className="image-container" onClick={() => { setselectedimage(imageUrl) }}>
            <img src={imageUrl} alt={`Image ${index}`} />
          </div>
        ))}
      </div>
</div>


      <div className='productinfo'>
        <div className='productinfo-left'>
        </div>
        <div className='productinfo-right'>
          <div className='productinfo-right-div'>
            <h2 style={{ textTransform: 'capitalize' }}>Product Detail</h2>
            <br />
            <div className='productinfo-right-bookmark' style={{ textTransform: 'capitalize' }}>
              <div dangerouslySetInnerHTML={{ __html: productdetail?.product?.productDescription }} />
              <img src={bookmark} alt='Bookmark' />
            </div>
          </div>
        </div>
      </div>
      <div className='productslide-cards'>
        {Relatedproduct?.map((product, index) => (
          <div className='productslide-card' onClick={() => { productdetailpage(product) }}>
            {
              product?.productImageUrl ?
                <img src={product?.productImageUrl} alt="T-shirt" className='Imagesize' />
                :
                <img src={img} alt="T-shirt" className='Imagesize' />
            }
            <br />
            <p>{product?.productTitle}</p>
            <p>Rs. {product?.salePrice}</p>
          </div>
        ))}
      </div>
      <br />
      <Footer />
    </>
  )
}

export default ProductDetail