import React, { useEffect, useState } from 'react'
import img from '../Assets/WhatsApp_Image_2024-02-03_at_12.14 1.svg'
import { GetApi } from '../Utilis/Api_call';
import { useNavigate } from 'react-router-dom';

function Newarrival() {

    const navigate = useNavigate()

    const [NewArrival, setNewArrival] = useState([]);

    const fetchData = async () => {
        try {
            const response = await GetApi(`/product/all?newarrivals=true&images=true`);
            const data = response.data.data.products;
            console.log("data", data);
            setNewArrival(data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const addToCart = (p) => {
        navigate(`/product/${p?.productSlug}/${p?._id}`)
    };


    return (
        <div className='tshirt' style={{ paddingLeft: '2%', paddingRight: '2%' }}>
            {NewArrival?.map((tshirt, index) => (
                <div onClick={() => addToCart(tshirt)} key={tshirt?._id} className={`tshirt-card${(index + 1) % 2 === 0 ? '2' : '3'}`}>
                    {
                        tshirt?.productImageUrl ?
                            <img src={tshirt?.productImageUrl} alt="T-shirt" className='Imagesize' />
                            :
                            <img src={img} alt="T-shirt" className='Imagesize' />
                    }
                    <div className="overlay">
                    <div className="bookmark-icon">
                            <div>
                           <h3>{tshirt?.productTitle}</h3>     
                            </div>
                            <div>
                             <h4> Rs. {tshirt?.salePrice?.toFixed(2)}</h4>  </div>
                        </div>
                        <br/>
                        <br/>
                        <button className="add-to-cart" onClick={() => addToCart(tshirt)}>Add to Cart</button>
                      
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Newarrival