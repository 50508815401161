import React, { useState } from 'react';
import logo from '../Assets/Group 5.svg'

function Footer() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
  <>
    <footer>
    <br/>
        <div className='footer-header'>

        <div className='footer-header-left'>
<p>be the first to access BELLARMIN launches and events. GDPR compliant</p>
<br/>
<div className='footer-input'>
    <input placeholder='Your Email'/>
    <button>Sign Up</button>
</div>
</div>
 <div className='footer-header-right'>
<p>Don't see your country? Signup to receive updates</p>
<p>when it's available. Notify Me</p>
</div>

        </div>
        
        <div className='footer-list'>
        <ul>
        ABOUT BELLARMIN
        <br/>
      
        <li>Retail Locations</li>
        <li>Stockists</li>
        <li>Careers</li>
        <li>Community</li>
        <li>TMALL</li>
        <li>FARFETCH</li>
        <li>BELLARMIN PRIZE</li>

        </ul>
        <ul>
        ABOUT BELLARMIN
        <br/>
      
        <li>Retail Locations</li>
        <li>Stockists</li>
        <li>Careers</li>
        <li>Community</li>
        <li>TMALL</li>
        <li>FARFETCH</li>
        <li>BELLARMIN PRIZE</li>

        </ul>
        <ul>
        ABOUT BELLARMIN
        <br/>
 
        <li>Retail Locations</li>
        <li>Stockists</li>
        <li>Careers</li>
        <li>Community</li>
        <li>TMALL</li>
        <li>FARFETCH</li>
        <li>BELLARMIN PRIZE</li>

        </ul>
        <img src={logo}/>


        </div>
        <br/>
        <br/>
<div className='accodian'>
  
<div>
  

  <div>
    <button
      className={`accordion ${activeIndex === 0 ? 'active' : ''}`}
      onClick={() => toggleAccordion(0)}
    >
    <p>
ABOUT BELLARMIN

</p>      
<h2>+</h2>

    </button>
    <div className={`panel ${activeIndex === 0 ? 'show' : ''}`}>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
    </div>
  </div>

  <div>
    <button
      className={`accordion ${activeIndex === 1 ? 'active' : ''}`}
      onClick={() => toggleAccordion(1)}
    >
     <p>
ABOUT BELLARMIN

</p>      
<h2>+</h2>

    </button>
    <div className={`panel ${activeIndex === 1 ? 'show' : ''}`}>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
    </div>
  </div>

  <div>
    <button
      className={`accordion ${activeIndex === 2 ? 'active' : ''}`}
      onClick={() => toggleAccordion(2)}
    >
<p>
ABOUT BELLARMIN

</p>      
<h2>+</h2>

</button>
    <div className={`panel ${activeIndex === 2 ? 'show' : ''}`}>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
    </div>
  </div>
</div>
<br/>
<br/>
       <div className='center'>
       <img src={logo}/>

       </div>
       <br/>
<br/>

</div>
       
    </footer>
  </>
  )
}

export default Footer