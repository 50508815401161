import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { CreteApi } from '../Utilis/Api_call'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [update, setupdate] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const handleLogin = async () => {

    if (!email.trim()) {
      toast.error("email Is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if (!password.trim()) {
      toast.error("password Is Required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    setupdate(true)

    try {
      const postData = {
        email: email,
        password: password,
      };
      const response = await CreteApi('/user/signin', postData);
      toast.success(response?.data?.data?.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const { user } = response.data.data;
      setupdate(false)

      localStorage.setItem("UserToken", user?.token);
      localStorage.setItem("Userid", user._id);
      localStorage.setItem("Userdata", JSON.stringify(user));
      navigate("/");
      setEmail("");
      setPassword("");
      window.location.reload();
      console.log('Authentication successful:', response.data);
    } catch (error) {
      setupdate(false)
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Navbar />

      <div className='login'>
        <h1>Login</h1>
        <div className='login-form singup-form'>
          <input
            placeholder='Email'
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
          />
          <br />
          <input
            placeholder='Password'
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
          />
          <br />
          <br />
          <p>Forgot Your Password?</p>
          <br />
          <br />
          {update ? (
            <button disabled>Please wait...</button>
          ) : (
            <button onClick={() => handleLogin()}>SIGN IN</button>
          )}
          <br />
          <p onClick={()=>{navigate("/signup")}}>Create An Account</p>
        </div>
      </div>
      <br/>
      <br/>
      <Footer />
    </>
  );
}

export default Login;
