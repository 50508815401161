import axios from "axios";
import { Api_url } from "./Api_url";


const GetApi = async (apiEndpoint) => {
    try {
        const response = await axios.get(`${Api_url}${apiEndpoint}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

const DeleteApi = async (apiEndpoint, id) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const response = await axios.delete(
            `${Api_url}${apiEndpoint}/${id}`,
            config
        );
        return response;
    } catch (error) {
        console.error('Error deleting priest:', error);
        throw error;
    }
};


const CreteApi = async (apiEndpoint, postData) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const postDataCopy = JSON.parse(JSON.stringify(postData));
        const response = await axios.post(
            `${Api_url}${apiEndpoint}`,
            postDataCopy,
            config
        );
        return response;
    } catch (error) {
        console.error('Error creating:', error);
        throw error;
    }
};

const CreteApiWithAuthorization = async (apiEndpoint, postData, authToken) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${authToken}` // Include authorization token
            },
        };
        const postDataCopy = JSON.parse(JSON.stringify(postData));
        const response = await axios.post(
            `${Api_url}${apiEndpoint}`,
            postDataCopy,
            config
        );
        return response;
    } catch (error) {
        console.error('Error creating:', error);
        throw error;
    }
};



const AuthGetApi = async (apiEndpoint, authToken) => {
    try {
        const response = await axios.get(`${Api_url}${apiEndpoint}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


const DeleteApiWithAuthorization = async (apiEndpoint,data,authToken) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${authToken}` // Include authorization token
            },
            data: JSON.stringify(data) // Include request body data
        };
        const response = await axios.delete(
            `${Api_url}${apiEndpoint}`,
            config
        );
        return response;
    } catch (error) {
        console.error('Error deleting:', error);
        throw error;
    }
};



export { GetApi,CreteApiWithAuthorization, DeleteApi, CreteApi ,AuthGetApi,DeleteApiWithAuthorization}