import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import product from '../Assets/tshit.png'
import { GetApi } from '../Utilis/Api_call';
import { useParams, useNavigate } from 'react-router-dom';

import img from '../Assets/WhatsApp_Image_2024-02-03_at_12.14 1.svg'

function MenDenim() {
        const navigate = useNavigate();
        const { id } = useParams();

        const [Subcategory, setSubcategory] = useState([]);
        const [denimProducts, setDenimProducts] = useState([]);
        const [activeTab1, setActiveTab1] = useState(0);
        const [getsubslug, setGetsubslug] = useState('');

        const handleTabChange = (index, slug) => {
                setActiveTab1(index);
                setGetsubslug(slug);
        };

        const getSubcategory = async () => {
                try {
                        const response = await GetApi(`/product/category/subcategory/${id}`);
                        const data = response?.data?.data?.subCategories;
                        setGetsubslug(data[0]?.slug);
                        setSubcategory(data);
                } catch (error) {
                        console.log("error", error);
                }
        };

        const fetchProducts = async () => {
                try {
                        const response = await GetApi(`/product/category/products/${getsubslug}?&images=true`);
                        const data = response?.data?.data?.products;
                        setDenimProducts(data);
                } catch (error) {
                        console.log("error", error);
                }
        };

        const addToCart = (product) => {
                navigate(`/product/${product?.productSlug}/${product?._id}`);
        };

        useEffect(() => {
                getSubcategory();
        }, []);

        useEffect(() => {
                if (getsubslug) {
                        fetchProducts();
                }
        }, [getsubslug]);


        return (
                <>
                        <Navbar />
                        <div className='banner3'>
                                <p>New In</p>
                                <h1>MEN'S DENIM</h1>
                        </div>
                        <br />
                        <br />
                        <div className="container">
                                <div className="btn-group" role="group">
                                        {Subcategory?.map((e, i) => (
                                                <button
                                                        key={i}
                                                        type="button"
                                                        className={`btn ${activeTab1 === i ? "selected-btn" : "select-btn"}`}
                                                        onClick={() => handleTabChange(i, e?.slug)}
                                                        style={{ marginRight: '10px' }}
                                                >
                                                        {e?.name}
                                                </button>
                                        ))}
                                </div>
                        </div>
                        <br />
                        <br />
                        <div className='denim-cards' style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                {denimProducts?.map(product => (
                                        <div className='denim-card' key={product?._id} onClick={() => addToCart(product)} >
                                                {
                                                        product?.productImageUrl ?
                                                                <img src={product?.productImageUrl} alt="T-shirt" className='Imagesize' />
                                                                :
                                                                <img src={img} alt="T-shirt" className='Imagesize' />
                                                }
                                                <h3>{product?.productTitle}</h3>
                                                <p>${product?.salePrice} USD</p>
                                        </div>
                                ))}
                        </div>
                        <br />
                        <br />
                        {/* <div className='new-cards'>
                                <div className='new-card'>
                                        <div className='new-card-img'>
                                                <img src={product} />

                                        </div>
                                        <div className='new-card-text'>
                                                <p>MenDenim</p>
                                                <h2>MenDenim</h2>
                                                <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</h3>
                                                <br />
                                                <div className='ions'>
                                                        <h4><del>$ 1000</del> $ 999</h4>
                                                        <div>
                                                                <i class="bi bi-cart-plus-fill"></i>
                                                                <i class="bi bi-heart-fill"></i>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className='new-card'>
                                        <div className='new-card-img'>
                                                <img src={product} />
                                        </div>
                                        <div className='new-card-text'>
                                                <p>MenDenim</p>
                                                <h2>MenDenim</h2>
                                                <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</h3>
                                                <br />
                                                <div className='ions'>
                                                        <h4><del>$ 1000</del> $ 999</h4>
                                                        <div>
                                                                <i class="bi bi-cart-plus-fill"></i>
                                                                <i class="bi bi-heart-fill"></i>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className='new-card'>
                                        <div className='new-card-img'>
                                                <img src={product} />
                                        </div>
                                        <div className='new-card-text'>
                                                <p>MenDenim</p>
                                                <h2>MenDenim</h2>
                                                <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</h3>
                                                <br />
                                                <div className='ions'>
                                                        <h4><del>$ 1000</del> $ 999</h4>
                                                        <div>
                                                                <i class="bi bi-cart-plus-fill"></i>
                                                                <i class="bi bi-heart-fill"></i>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className='new-card'>
                                        <div className='new-card-img'>
                                                <img src={product} />
                                        </div>
                                        <div className='new-card-text'>
                                                <p>MenDenim</p>
                                                <h2>MenDenim</h2>
                                                <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</h3>
                                                <br />
                                                <div className='ions'>
                                                        <h4><del>$ 1000</del> $ 999</h4>
                                                        <div>
                                                                <i class="bi bi-cart-plus-fill"></i>
                                                                <i class="bi bi-heart-fill"></i>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div> */}
                        <br />
                        <br />
                        <Footer />
                </>
        )
}

export default MenDenim